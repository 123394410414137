




import { BaseStripeInfo } from "@/mixins/Stripe";
import { StripeMerchantInfo } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";

@Component({ name: "settings-account-return" })
export default class SettingsAccountReturn extends mixins(BaseStripeInfo) {
  public async mounted(): Promise<void> {
    const merchantId = this.$route.query.accountId.toString();
    // fetch the account info from stripe and decide whether to set status as true or false
    const accountInfo = await this.getAccountInfo(merchantId);
    const newMerchantData: Partial<StripeMerchantInfo> = {
      status:
        accountInfo.charges_enabled &&
        accountInfo.details_submitted &&
        accountInfo.payouts_enabled,
      merchantId
    };
    await this.$store.dispatch("updateMerchantInfo", newMerchantData);
    this.$router.push({ name: "Settings-Account" });
  }
}
